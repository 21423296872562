exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portraits-js": () => import("./../../../src/pages/portraits.js" /* webpackChunkName: "component---src-pages-portraits-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-weddings-custom-js": () => import("./../../../src/pages/weddings/custom.js" /* webpackChunkName: "component---src-pages-weddings-custom-js" */),
  "component---src-pages-weddings-finishing-touches-js": () => import("./../../../src/pages/weddings/finishing-touches.js" /* webpackChunkName: "component---src-pages-weddings-finishing-touches-js" */),
  "component---src-pages-weddings-house-collection-js": () => import("./../../../src/pages/weddings/house-collection.js" /* webpackChunkName: "component---src-pages-weddings-house-collection-js" */),
  "component---src-pages-weddings-on-the-day-js": () => import("./../../../src/pages/weddings/on-the-day.js" /* webpackChunkName: "component---src-pages-weddings-on-the-day-js" */),
  "component---src-templates-house-collection-invite-js": () => import("./../../../src/templates/HouseCollectionInvite.js" /* webpackChunkName: "component---src-templates-house-collection-invite-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/Policy.js" /* webpackChunkName: "component---src-templates-policy-js" */)
}

